<template>

  <header>
    <div class="grid-header" :class="{ 'padding-0': !showMobileTopBar, 
                                       'extra-margin': displayMobileSearch, 
                                       'default-margin': !displayMobileSearch }">

      <span v-show="showMobileTopBar" class="mobile-menu-wrapper">
          <a href="#mobile-menu" aria-label="mobile menu link">
              <i class="fas fa-bars"></i>
          </a>

          <client-only v-if="appData.clientMounted">
            <mobile-menu v-if="isMobileOrTablet" />
          </client-only>
      </span>

      <template v-if="showMobileTopBar">
        <div class="mobile">
          <div class="container">
            <added-to-cart
                v-if="displayAddedToCartMobile"
                v-click-outside="() => displayAddedToCartMobile = false"
                @manageAddedToCart="displayAddedToCartMobile = $event"/>

            <nuxt-link :to="{ name: 'index' }">
              <div class="sn">
                <img src="@/../shared/assets/images/small-logo.svg" alt="S&N" class="small-mobile"
                     width="35"
                     height="35"
                     loading="lazy"/>
                <img src="@/../shared/assets/images/main-logo.svg"
                     width="320"
                     height="26"
                     alt="Scout & Nimble"
                     class="big-mobile"
                     loading="lazy" />
              </div>
            </nuxt-link>
            <div>
              <button class="search-button" aria-label="Search Button" @click="toggleSearch">
                <i class="fas fa-search search-button-icon"/>
              </button>
            </div>
            <div class="icons">

              <!-- <div class="favorites">
                <nuxt-link :to="{ name: 'favorites' }" class="icon-wrapper">
                  <i class="fas fa-heart"></i>
                  <span class="big-mobile icon-description">Favorites</span>
                </nuxt-link>
              </div> -->

              <nuxt-link v-if="signedIn" :to="{ name: 'user-dashboard' }" class="icon-wrapper" aria-label="my account">
                <i class="fas fa-user-circle signed"></i>
                <span class="big-mobile icon-description">My Account</span>
              </nuxt-link>

              <nuxt-link v-else to="/login" class="icon-wrapper" aria-label="sign in">
                <i class="fas fa-user-circle"></i>
                <span class="big-mobile icon-description">Sign in</span>
              </nuxt-link>

              <nuxt-link :to="{ name: 'cart' }" aria-label="cart">
                <div class="cart icon-wrapper">
                  <div v-if="this.$store.getters['order/getLineItems'] &&
                               this.$store.getters['order/getLineItems'].length > 0">
                    <i class="fas fa-shopping-cart"></i>
                    <span class="cart-amount"
                          v-if="this.$store.getters['order/getLineItems'] &&
                                    this.$store.getters['order/getLineItems'].length > 0">
                                      {{ this.$store.getters['order/getLineItems'].length }}
                                </span>
                  </div>

                  <div v-else>
                    <i class="fas fa-shopping-cart"></i>
                  </div>
                  <span class="big-mobile icon-description">My Cart</span>
                </div>
              </nuxt-link>
            </div>

          </div>
        </div>
      </template>

      <nuxt-link to="/" class="scout-and-nimble big-screen">
        <img src="@/../shared/assets/images/main-logo.svg"
             width="320"
             height="26"
             alt="Scout & Nimble"
             loading="lazy" />
      </nuxt-link>

      <client-only v-if="appData.clientMounted">
        <search v-if="!isMobileOrTablet && displaySearch" v-resize="handleResize" class="big-screen"/>
      </client-only>

      <div class="right big-screen">

        <div class="welcome-back" v-if="signedIn">
          Welcome back,
          <nuxt-link to="/">
                        <span v-if="user.first_name && user.last_name">
                            {{ user.first_name }} {{ user.last_name }}
                        </span>
            <span v-else>
                            {{ user.email }}
                        </span>
          </nuxt-link>
        </div>

        <div class="account"
             v-click-outside="() => {displayProfileMenu = false}"
             v-if="signedIn">
          <i class="fas fa-user-circle"
             :class="{active: displayProfileMenu || isInMyProfile}"
             @click="displayProfileMenu = !displayProfileMenu"></i>
          <profile-menu v-if="displayProfileMenu" @close="displayProfileMenu = false"/>
        </div>
        <div class="account" v-else>
          <span class="sign-in" role="button" tabindex="0" @click="logIn(true)">sign in</span>
          |
          <span class="sign-up" role="button" tabindex="0" @click="logIn(false)">create an account</span>
        </div>

<!--        <div class="favorites">-->
<!--          <nuxt-link to="/favourites" v-tooltip="'Wishlist'">-->
<!--            <i class="fas fa-heart"></i>-->
<!--          </nuxt-link>-->
<!--        </div>-->

        <div class="cart" 
             role="button" 
             aria-label="My cart" 
             tabindex="0"
             v-click-outside="() => {displayCart = false}">

          <div @click="displayCart = !displayCart"
               v-if="this.$store.getters['order/getLineItems'] &&
                           this.$store.getters['order/getLineItems'].length > 0">
            <i class="fas fa-shopping-cart"
               :class="{active: displayCart}"
               v-tooltip="'My cart'"></i>
            <span class="cart-amount"
                  v-if="this.$store.getters['order/getLineItems'] &&
                                this.$store.getters['order/getLineItems'].length > 0">
                        {{ this.$store.getters['order/getLineItems'].length }}
                    </span>
          </div>
          <div @click="goToCart"
               v-else>
            <i class="fas fa-shopping-cart"
               v-tooltip="'My cart'"></i>
          </div>

          <added-to-cart v-if="displayAddedToCartDesktop"
                          v-click-outside="() => { displayAddedToCartDesktop = false }"
                          @manageAddedToCart="displayAddedToCartDesktop = $event"/>

          <cart-preview v-if="displayCart"
                        @manageDisplayCart="manageDisplayCart"/>

        </div>
      </div>
    </div>

    <div class="mobile-search-wrapper" 
          :class="{ 'show': displayMobileSearch && displaySearch && isMobileOrTablet, 
                    'hidden': !displayMobileSearch && !displaySearch && !isMobileOrTablet }">
      <div class="mobile-search">
        <client-only>
          <search v-if="displaySearch && isMobileOrTablet" class="search-box-mobile" />
        </client-only>
      </div>
    </div>
  </header>

</template>

<script>
import CartPreview from '@/components/CartPreview'
import ProfileMenu from '@/components/ProfileMenu'
import ClickOutside from 'vue-click-outside'
import ApiUser from '@/api/User'
import LazyHydrate from 'vue-lazy-hydration'
import WindowSizeMixin from '@/mixins/windowSize'
import SkeletonBox from '@/components/SkeletonBox'

export default {
  name: 'the-header',
  inject: ['appData'],
  mixins: [WindowSizeMixin],
  data: () => {
    return {
      displayAddedToCartMobile: false,
      displayAddedToCartDesktop: false,
      displayCart: false,
      displaySearch: true,
      displayMobileSearch: false,
      displayProfileMenu: false,
      user: {},
    }
  },
  directives: {
    ClickOutside
  },
  components: {
    Search: () => import('@/components/Search'),
    CartPreview,
    MobileMenu: () => process.client ? import('@/components/MobileMenu') : null,
    ProfileMenu,
    LazyHydrate,
    SkeletonBox,
    AddedToCart: () => import('@/components/AddedToCart')
  },
  methods: {
    toggleSearch() {
      this.displayMobileSearch = !this.displayMobileSearch;
    },
    goToCart() {
      this.$router.push({name: 'cart'})
    },
    manageDisplayCart(val) {
      this.displayCart = val
    },
    handleResize() {
      if (this.$route.name === 'checkout' && window.innerWidth <= 992) {
        this.displaySearch = false
      } else {
        this.displaySearch = true
      }
    },
    logIn(initialLogin) {
      this.$store.dispatch('modal/showModal', {component: 'SignForm', props: {initialLogin: initialLogin}})
    },
    isInMyProfile() {
      return (this.$route.path === "/user/edit" || this.$route.path === "/user/details")
    },
    signOut() {
      this.$store.dispatch('auth/signOut')
          .then(response => {
            this.$router.push({name: 'index'})
          })
          .catch(error => {
            console.error(error)
          })
    },
    closeProfileMenu() {
      this.displayProfileMenu = false
    },
    closeCart() {
      this.displayCart = false
    },
    getUser() {
      ApiUser.getUser(this.$axios)
          .then(response => {
            this.user = response.data
          })
          .catch(error => {
            console.error(error)
          })
    }
  },
  watch: {
    signedIn(newValue) {
      if (newValue) {
        this.getUser()
      } else {
        this.user = {}
      }
    }
  },
  computed: {
    signedIn() {
      return this.$store.getters['auth/signedIn']
    },
    productionEnv() {
      return process.env.PRODUCTION
    },
    showMobileTopBar() {
      return this.$store.getters['globalData/getShowMobileTopBar']
    }
  },
  mounted() {
      this.$root.$on('displayAddedToCart', () => {
          this.displayAddedToCartMobile = true
          this.displayAddedToCartDesktop = true
      })
  },
  created() {
    if (process.server) { return }

    if (window.location.href.match(/checkout$/) && window.innerWidth <= 992) {
        this.displaySearch = false
    } else {
      this.displaySearch = true
    }

  },
  async fetch () {
    if (!this.signedIn) { return }

    await ApiUser.getUser(this.$axios)
      .then(response => {
        this.user = response.data
      })
      .catch(error => {
        console.error(error)
      })
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/variables.scss';

@media (min-width: $tablet-max + 1px) {
  header {
    padding: 15px 0;
  }

  .mobile-search-wrapper{
    display: none;
  }
}

@media (max-width: $tablet-max){
  .extra-margin{
    margin-bottom: 61px;
    transition: margin-bottom 0.7s ease;
  }

  .default-margin{
    margin-bottom: 0;
    transition: margin-bottom 0.7s ease;
  }

  header{
    position: relative;
  }
}

.mobile-search-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 61px;
  background-color: #fff;
  transition: top 0.7s ease;
  box-sizing: border-box;
  z-index: 1;
}

.mobile-search-wrapper.show {
  top: 61px;
}

.mobile-search-wrapper.hidden {
  top: 0px;
}

.grid-header {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  font-family: MotivaSans;
  position: relative;
  z-index: 18;
  background-color: #fff;
  transition: margin-bottom 0.7s ease;

  @media only screen and (max-width: $tablet-max) {
    height: 50px;
    padding-top: 10px;

    &.padding-0 {
      padding: 0 !important;
    }
  }

  .mobile-menu-wrapper {
    display: none;
    transform: translateY(5px);

    @media only screen and (max-width: $tablet-max) {
      display: inline-block;
    }
  }

  .scout-and-nimble {
    text-decoration: none;
    font-family: Tenez;
    font-size: 5rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 1.5px;
    text-align: left;
    color: $color-gray-header;

    img {
      width: 313px;
    }
  }

  .search-button{
    background: none;
    border: none;
    margin-left: 10px;
    font-size: 2rem;
    font-weight: 900;
    color: $color-light-black;
    cursor: pointer;
  }

  .right {
    align-self: center;
    justify-self: end;
    align-items: center;

    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-column-gap: 15px;
    grid-row-gap: 0px;

    .account, .favorites, .cart {
      i {
        padding: 5px;
      }
    }

    .welcome-back {
      grid-column: 1/2;
      color: #3e3e3e;
      font-size: 1.5rem;
      grid-row: 1;

      a {
        color: #3e3e3e;
        font-weight: bold;

        &:hover {
          color: #653932
        }
      }
    }

    .account {
      grid-column: 2/3;
      font-size: 1.5rem;
      grid-row: 1;

      .sign-in, .sign-up {
        text-transform: uppercase;
        cursor: pointer;
        color: $color-gray-header;
      }

      .sign-in {
        color: $color-gray-header;
      }

      .sign-up {
        font-weight: bold;
        color: #000;
      }
    }

    .favorites {
      grid-column: 3/4;
      grid-row: 1;
      font-size: 1.6rem;
    }

    .cart {
      grid-column: 4/5;
      grid-row: 1;
      font-size: 1.6rem;
      position: relative;
      cursor: pointer;

      .active {
        color: #653932;
      }

      .cart-amount {
        position: absolute;
        top: -1px;
        right: -3px;
        background: #a01616;
        color: #fff;
        text-align: center;
        border-radius: 100%;
        width: 14px;
        height: 14px;
        font-size: 1rem;
      }
    }

    .favorites, .cart {
      a {
        color: #000;
        text-decoration: none;

        &:visited, &:focus {
          color: #000;
        }

        &:hover {
          color: #653932;
        }
      }
    }

    .account {
      i {
        cursor: pointer;

        &.active {
          color: #653932;
        }
      }
    }

  }

  @media only screen and (max-width: $tablet-max) {
    grid-template-columns: min-content 1fr;
    border-bottom: 1px solid #CCCCCC;
  }

  .mobile {
    display: none;

    @media only screen and (max-width: $tablet-max) {
      display: block;
    }

    .container {
      display: grid;
      grid-template-columns: auto 1fr auto;
      margin-top: 3px;
      align-items: center;

      @media only screen and (min-width: 768px) {
        margin-top: 6px;
      }
    }

    .icons {
      display: grid;
      grid-template-columns: repeat(3, auto);
      font-size: 2.4rem;
      align-items: center;
      grid-column-gap: 22px;
      padding-right: 15px;

      .icon-description {
        font-family: MotivaSans;
        font-size: 1.6rem;
        padding-left: 12px;
      }

      .icon-wrapper {
        display: flex;
        align-items: center;
      }
    }

    .sn {
      padding-left: 8px;
      cursor: pointer;
      width: fit-content;

      img {
        &.small-mobile {
          width: 35px;
        }

        &.big-mobile {
          width: 243px;
        }
      }
    }

    .cart {
      position: relative;

      .cart-amount {
        position: absolute;
        top: -1px;
        right: -5px;
        background: #a01616;
        color: #fff;
        text-align: center;
        border-radius: 100%;
        width: 14px;
        height: 14px;
        font-size: 1rem;

        @media screen and (min-width: 769px) {
          left: 18px;
        }
      }
    }

  }

  .signed {
    color: #B17368;
  }

  .big-screen {
    @media only screen and (max-width: $tablet-max) {
      display: none;
    }
  }
}

.mobile-search {
  box-sizing: border-box;
  display: none;
  border-bottom: 1px solid #CCCCCC;
  text-align: center;
  width: 100%;
  height: 61px;
  align-items: center;

  &.top {
    top: 0;
  }

  .search {
    margin: 0 auto;
    width: 100% !important;
    display: flex;
    justify-content: center;

    /deep/ input {
      width: 100%;
    }


  }

  @media only screen and (max-width: $tablet-max) {
    display: flex;
  }
}

.small-mobile {
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
}

.big-mobile {
  display: none;

  @media screen and (min-width: 769px) and (max-width: $tablet-max) {
    display: block;
  }
}

</style>
